




import Vue from "vue"
import LoadingOverlay from "~/components/guideline/loading/LoadingOverlay.vue"

export default Vue.extend({
  components: {
    LoadingOverlay,
  },
  layout: "home",
  beforeMount() {
    if (
      this.$route.query.standalone === "true" ||
      process.env.APP_ENV !== "production"
    ) {
      this.$router.push("/projects")
    } else {
      window.location.href = "https://start.crowdlinks.jp"
    }
  },
})
